
.logo-box img {
  width: 100%;
}
.back-btn {
  padding: 20px;
  border: 1px solid #1f2e67;
  font-size: 20px;
  color: #333;
  margin: 10px auto;
  display: inline-block;
  transition: all .3s;
}
.back-btn:hover {
  background: #1f2e67;
  color: #fff;
}
.page-footer {
  margin: 50px 0;
}
.editor-content h2.bg-blue {
  background: #1f2e67;
  color: #fff;
  font-size: 30px;
  padding: 10px 0;
}
.detail-column .product_tags {
  margin-top: 30px;
}
.case-single-section .upper-section .detail-column .product-column h2 {
  position: relative;
  letter-spacing: 2px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4em;
  padding: 10px 30px;
  background-color: #1f2e67;
}

.case-single-section .upper-section .product-column .detail-list li:nth-child(even) {
  background-color: #f5f5f5;
}
.case-single-section .upper-section .product-column .detail-list li:before {
  content: '\f0da';
  font-family: FontAwesome;
  color: #e60012;
  padding-right: 10px;
  font-size: 18px;
}
.editor-content .contact-box {
  border: 3px #00173b solid;
  padding: 20px 30px 10px;
}
.editor-content .contact-box h3 {
  font-size: 26px;
  color: #de1c1c;
  margin-bottom: 20px;
}
.editor-content .contact-box span {
  font-size: 20px;
  color: #333;
  display: block;
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: 300;
}
.editor-content .icon-text {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
}
.editor-content .icon-text img {
  margin-right: 20px;
}
.editor-content .icon-text li {
  font-size: 28px;
  font-weight: 500;
  color: #333;
}
.editor-content p {
  font-weight: 300;
  font-size: 25px;
  color: #000;
  margin-bottom: 1.3em;
}
.editor-content p span {

}
.editor-content h2{
  color: #1f2e67;
  letter-spacing: 2px;
  margin: 30px auto;
  font-size: 36px;
}
.editor-content h2:after {
  content: ' ';
  border: 0;
  border-bottom: 3px solid #1f2e67;
  margin-top: 5px;
  display: block;
  width: 40px;
}
.page-title h2:before {
  content: ' ';
  position: absolute;
  width: 148px;
  height: 121px;
  top: -40px;
  left: -130px;
}
.header-button a {
  color: #fff;
  display: block;
}
.header-button .facebook a{
  padding: 33px 20px;
}
.header-button .facebook {
  font-size: 1.5rem;
  background: #3b5998;
}
.header-button .language_select a{
  padding: 25px 20px 24px;
}
.header-button .language_select {
  font-size: 1.2rem;
  background: #1f2e67;
}
.floating-bar .float-menu li:last-child {
  padding-bottom: 10px;
}
.floating-bar .float-menu li:last-child:before {
  width: 100%;
  height: 1px;
  content: ' ';
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .2);
}
.floating-bar .float-menu hr {
  margin: 0 0 10px;
}
.floating-bar .float-menu {
  background: #1f2e67;
}

.m-fixed-bar {
  display: none;
}
.floating-bar {
  position: fixed;
  bottom: 20%;
  right: 15px;
  z-index: 10;
  background: #00173b;
}

.floating-bar a {
  display: inline-table;
  padding: 10px 15px;
  color: #fff;
  font-size: 20px;
  writing-mode: tb;
}
.floating-bar a i{
  font-size: 26px;
  margin-bottom: 10px;
}
.about-btn {
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  border: 1px solid #fff;
}
.about-btn:hover {
  color: #1f2e67;
}
.sec-title span {
  font-size: 24px;
  color: #e50303;
}
.call-to-action h3 {
  text-align: center;
  color: #fff;
  line-height: 2.5em;
}
.services-block .inner-box:hover h5{
  background-color: transparent;
}

.services-block .inner-box:hover a{
  color: #fff;
}

.title-box {
  display: flex;
  align-items: center;
}
.services-section .title-box h3{
  font-size: 36px;
  color: #333;
  line-height: 1.4em;
  font-weight: 700;
  padding-right: 20px;
  border-right: 3px solid #1f2e67;
  margin-right: 20px;
}

.services-section .title-box span {
  font-size: 24px;
  line-height: 1.4em;
  color: #e50303;
}

.header-button ul li a img {
  height: 90px;
}


.header-button ul{
  display: flex;
}

.main-header .auto-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}
footer p {
  margin: 0;
  font-size: 13px;
}
footer .footer-widget {
  display: flex;
  align-self: center;
  flex-direction: column;
  height: 100%;
}

footer .footer-widget li {
  margin: 0 10px;
}

.footer-widget .sec-title {
  margin-bottom: 0;
}
.footer-widget li h2 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;
}
.footer-widget .sec-title h2:after {
  background-color: #fff;
  bottom: 5px;
}
.footer-widget li p {
  color: #fff;
  margin-bottom: 0;
}
footer .bottom-text {
  display: flex;
}
.editor-content h1.gray-bg {
  color: #de1c1c;
  font-size: 30px;
  background: linear-gradient(360deg, #e0e0e0 0%, #fff 100%);
  padding: 14px 0 10px 20px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.editor-content blockquote {
  border: #de1c1c 2px solid;
  padding: 40px;
  font-size: 25px;
  line-height: 1.5;
  color: #000;
}
.editor-content .huge-font {
  line-height: 1.5;
  font-size: 40px;
  font-weight: normal;
}

@media only screen and (max-width: 480px) {
  .breadcrumb-outer .bread-crumb {
    padding: 5px 0;
  }
  .breadcrumb-outer .bread-crumb li {
    font-size: 14px;
  }
  .editor-content h1.gray-bg {
    font-size: 22px;
    padding: 5px 10px;
  }
  .editor-content .huge-font {
    line-height: 1.5;
    font-size: 28px;
    font-weight: normal;
  }
  .editor-content blockquote {
    font-size: 16px;
    padding: 20px 10px;
  }
  .editor-content .icon-text img {
    margin-right: 5px;
    width: 25px;
  }
  .editor-content .icon-text li {
    font-size: 20px;
  }
  .editor-content .icon-text {
    margin-bottom: 50px;
  }
  .editor-content .contact-box h3 {
    font-size: 20px;
  }
  .editor-content .contact-box span {
    font-size: 18px;
  }
  .link-list li a {
    line-height: 1;
  }
  .main-footer .footer-bottom {
    border-top: none;
  }
  footer .bottom-text {
    flex-direction: column;
    align-items: center;
  }
  .link-list {
    height: auto;
  }
  .case-single-section .upper-section .detail-column .detail-list li {
    padding: 10px 5px;
  }
  .case-single-section {
    padding: 10px 0 40px;
  }
  .editor-content h2 {
    font-size: 24px;
    margin-top: 20px;
  }
  .editor-content h2:before {
    height: 16px;
  }
  .editor-content p {font-size: 16px;}
  .page-title {
    padding: 30px 0 29px 0;
  }
  .page-title h2 {
    font-size: 40px;
  }
  .page-title h2:before {
    width: 74px;
    height: 100%;
    background-size: contain;
    top: -10px;
    left: -60px;
  }

  .header-button .facebook a{
    font-size: 1.5rem;
    padding: 25px 35px;
  }
  .header-button .language_select a{
    font-size: 1.2rem;
    padding: 25px 20px;
  }
  .main-footer {
    margin-bottom: 45px;
  }
  .hidden-xs {
    display: none!important;
  }
  .m-fixed-bar {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .m-fixed-bar ul {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }
  .m-fixed-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    text-align: center;
    line-height: 45px;
    font-size: 20px;
    letter-spacing: 2px;
    background: #00173b;
  }
  .m-fixed-bar a i {
    margin: 0 10px;
    font-size: 28px;
  }
  .m-fixed-bar a {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .services-section .title-box {
    margin-bottom: 20px;
  }
  .services-section {
    padding: 40px 0 60px;
  }
  .map-info-section .outer-container .right-column .content {
    padding: 50px 30px;
  }
  .map-info-section .outer-container .right-column .content .text{
    font-size: 16px;
    margin-bottom: 50px;
  }
  .icon_section .col-sm-12 {
    margin-bottom: 20px;
  }
  .call-to-action h3 {
    font-size: 1.5rem;
    line-height: 1.5em;
  }

  footer .footer-widget {
    flex-wrap: wrap;
    flex-direction: column;
  }
  footer .footer-widget li {
    width: 100%;
    margin: 10px 0;
  }
  .main-header .auto-container {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .header-button {
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 15;
  }
  .header-button .language_select {
    background: transparent;
  }
  .header-button .language_select a {
    color: #1f2e67;
  }

  .header-button ul li a img {
    height: 70px;
  }

  .services-section .title-box h3 {
    font-size: 1.5rem;
  }
  .services-section .title-box span {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 996px) {

}

@media only screen and (min-width: 1200px) {

}